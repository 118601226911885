import * as Sentry from '@sentry/browser';
import { commonSentryConfig } from './common-sentry-config';

Sentry.init({
  ...commonSentryConfig,
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  tracePropagationTargets: ['www.stdcheck.local', 'stdcheck.com'],
  integrations: [Sentry.browserTracingIntegration()],
  ignoreErrors: ['BraintreeError: Cannot contact the gateway at this time.'],
  denyUrls: [
    'api.livechatinc.com',
    'bat.bing.com',
    'cdn.livechatinc.com',
    'cdn.ywxi.net',
    'connect.facebook.net',
    'my.hellobar.com',
    'www.mczbf.com',
    'www.shopperapproved.com',
    'www.trustedsite.com',
    'www.upsellit.com',
  ],
});
