/**
 * Common Sentry configuration for WP pages and ng-checkout.
 */
export const commonSentryConfig = {
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn: 'https://eb95b1465b5346148828776acb40c62c@o510915.ingest.sentry.io/6105269',
  release: process.env.SENTRY_RELEASE,
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      order_id: localStorage.getItem('transaction_id') ?? null,
    };

    return event;
  },
};
